








































































































































import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import { Vue, Component, PropSync, Ref, Prop } from 'vue-property-decorator'
import SeletorDeCliente from '@/views/application/clientes/SeletorDeCliente.vue'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import CheckboxButton from '@/components/ui/CheckboxButton.vue'
import { FindClienteUseCase } from '@/usecases'
import { Cliente, Pageable, Page, FiltroDeBuscaClientes } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeAlteracaoEmMassaDeClientes from '@/components/cliente/DialogoDeAlteracaoEmMassaDeClientes.vue'
import { VListItemContent } from 'vuetify/lib'
import { Fragment } from 'vue-fragment'
import { DataOptions } from 'vuetify'

@Component({
	components: {
		TelaGenerica,
		SeletorDeCliente,
		SeletorDeLojasDoUsuario,	
		CheckboxButton,
		DialogoDeAlteracaoEmMassaDeClientes,
		VListItemContent,
		Fragment,
	},
})

export default class TelaDeAlteracaoEmMassaDeClientes extends Vue {
	@PropSync('value') selecionado!: Cliente | Cliente[] | null
	@PropSync('selecionados', {
		type: Array,
		default: () => [],
	}) selecionadosSync!: string[]
	@Ref() dialogoDeAlteracaoEmMassaDeClientes!: DialogoDeAlteracaoEmMassaDeClientes
	@Prop() encurtado!: boolean
	@Prop({ type: Function }) to?: (cliente: Cliente) => void
	@Prop({ type: Array, default: () => [] }) clientes!: Cliente[]

	findClienteUseCase = new FindClienteUseCase()
	buscando = false
	selecionados: string[] = []
	todosForamSelecionados = false
	cancelToken: CancelTokenSource | null = null
	pagina: Page<Cliente> | null = null
	totalRegistros = -1
	usarTodosOsClientesFiltradosDaLoja = false

	filtro = criarFiltros()
	filtroAplicado: FiltroDeBuscaClientes = {}

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 40,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	async buscar(paginavel: Pageable = {page: 0, size: 40}) {
		try {
			this.buscando = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
			
			const params = {
				...this.filtroAplicado,
				page: this.paginacao.page - 1,
				lojaId: this.filtro.lojaId || undefined,
				size: this.paginacao.itemsPerPage,
				gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos ? UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id) : null,
				...paginavel,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			if(params.lojaId){
				this.pagina = await this.findClienteUseCase.findClientesVinculadosComLoja(params, axiosConfig)
				this.totalRegistros = this.pagina.totalElements
				this.clientes = this.pagina.content
			}
			else{
				AlertModule.setError('Favor informar Loja!')
			}
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.buscando = false
		}finally{
			this.buscando = false
		}
	}

	selecionarTodos() {
		this.selecionados = this.clientes.map(
			({ id }) => id,
		)

		this.$nextTick(() => {
			if (!this.pagina) return
			if (this.selecionados.length < this.pagina.totalElements)
				this.todosForamSelecionados = true
		})
	}

	limparSelecao() {
		this.selecionados = []
		this.todosForamSelecionados = false
		this.usarTodosOsClientesFiltradosDaLoja = false
	}

	filtrar(filtros: FiltroDeBuscaClientes = {}, paginavel: Pageable = {}) {
		this.filtroAplicado = {
			...filtros,
			lojaId: this.filtro.lojaId || '',
		}
		this.buscar(paginavel)
	}

	aplicarFiltros() {
		this.buscar()
	}

	limparBusca(){
		this.filtro.lojaId = ''
		this.pagina = null
		this.clientes = []
		this.buscando = false
		this.limparSelecao()
	}

	async abrirDialogoDeAlteracaoEmMassaDeClientes() {
		if(UserLoginStore.permiteRegraDeNegocio('pode-alterar-bloqueios-de-cliente')){
			this.dialogoDeAlteracaoEmMassaDeClientes.mostrar(this.selecionados, this.filtro.lojaId, this.usarTodosOsClientesFiltradosDaLoja)
		} else {
			AlertModule.setError('Usuário não possui permissão para alterar bloqueios de cliente.')
		}
	}

	atualizarClientes(clientes: Cliente){
		if (!clientes) return

		const indice = this.clientes.findIndex(
			clientesExistente => clientesExistente.id === clientes.id,
		)
		indice === -1
			? this.clientes.push(clientes)
			: this.clientes.splice(indice, 1, clientes)
	}

}

function criarFiltros(){
	return {
		lojaId: '',
	}
}

