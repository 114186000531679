








































































































import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindClienteUseCase, SaveClienteUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch, Prop } from 'vue-property-decorator'
import CampoDeTelefone from '@/components/ui/CampoDeTelefone.vue'
import { obrigatorio, maiorOuIgualAZero } from '@/shareds/regras-de-form'
import SeletorDeTipoDeCliente from '@/components/tabeladepreco/SeletorDeTipoDeCliente.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { TipoDeOperacao } from '@/models/Recurso'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import { nextTick } from '@/shareds/utils'
import SeletorDeUsuario from '../usuario/SeletorDeUsuario.vue'
import ListagemDeLojaPorCliente from '../loja/ListagemDeLojaPorCliente.vue'
import ListagemDosGruposEconomicosPorCliente from './ListagemDosGruposEconomicosPorCliente.vue'
import { FindLojaUseCase } from '@/usecases'
import { TipoDePagamento } from '@/models'
import { ClienteService } from '@/services'
import Confirmacao from '../ui/Confirmacao.vue'

type AbaDoCliente = 'Bloquear' | 'Liberar'

@Component({
	components: {
		SeletorDeTipoDeCliente,
		SeletorDeUsuario,
		ListagemDeLojaPorCliente,
		ListagemDosGruposEconomicosPorCliente,
		Confirmacao,
	},
})
export default class DialogoDeAlteracaoEmMassaDeClientes extends Vue {
	@Prop({ type: String, default: 'Gênero' }) placeholderGenero!: string;			
	@Prop() atualizaPorCampoDeCpf!: boolean
	@Ref() campoDeNome!: HTMLInputElement
	@Ref() form!: HTMLFormElement
	@Ref() confirmaAlteracoes!: Confirmacao
	
	salvando = false
	clientes: string[] = []
	mostra = false
	valid = false
	saveClienteUseCase = new SaveClienteUseCase()
	findClienteUseCase = new FindClienteUseCase()
	clienteService = new ClienteService()
	obrigatorio = obrigatorio
	maiorOuIgualAZero = maiorOuIgualAZero
	buscandoCnpjOuCpf = false
	errors: string[] = []
	tabs: AbaDoCliente[] = ['Bloquear', 'Liberar']
	tab: null | number = null

	findLojaUseCase = new FindLojaUseCase()
	bloquearTiposDePagamento = false
	liberarTiposDePagamento = false
	lojaId!: string 
	tiposDePagamentos: TipoDePagamento[] = []
	tiposDePagamentoSelecionados: string[] = []
	usarTodosOsClientesFiltradosDaLoja = false

	get abasPermitidas() {
		return this.tabs
			.filter(aba => {
				if (aba !== 'Bloquear') return true
				return UserLoginStore.permiteRegraDeNegocio('pode-alterar-bloqueios-de-cliente')
			})
			.filter(aba => {
				if (aba !== 'Liberar') return true
				return UserLoginStore.permiteRegraDeNegocio('pode-alterar-bloqueios-de-cliente')
			})
	}

	get podeAlterarCliente() {
		return this.podeRealizarOperacao('alterar')
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'clientes' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'clientes'))
	}

	$refs!: {
		[key: string]: HTMLFormElement | CampoDeTelefone
	}

	mostrar(selecionados: string[], loja: string, usarTodosOsClientesFiltradosDaLoja: boolean) {
		this.clientes = selecionados
		this.lojaId = loja
		this.mostra = true
		this.usarTodosOsClientesFiltradosDaLoja = usarTodosOsClientesFiltradosDaLoja
		this.buscaTiposDePagamentoLoja()
	}

	esconder() {
		this.mostra = false
	}

	async salvar() {
		await nextTick()
		if (!this.clientes) return
		try {
			this.salvando = true

			const clienteSalvo = await this.clienteService.alterarClienteEmMassaByFiltro(this.clientes, this.lojaId, this.bloquearTiposDePagamento, this.liberarTiposDePagamento, this.tiposDePagamentoSelecionados, this.usarTodosOsClientesFiltradosDaLoja)
			this.$emit('confirmado', clienteSalvo)
			this.$emit('clienteAtualizado')
			this.mostra = false
			AlertModule.setSuccess('Adicionado na fila de processos para ser executado')
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.tiposDePagamentoSelecionados = []
			this.bloquearTiposDePagamento = false
			this.liberarTiposDePagamento = false
		}
	}

	@Watch('mostra')
	onChangeMostra(mostra: boolean) {
		if(mostra) return
		this.clientes = []
	}	

	async buscaTiposDePagamentoLoja(){
		const loja = await this.findLojaUseCase.findLojaById(this.lojaId)
		this.tiposDePagamentos = loja.tiposDePagamento
	}

	handleBloquearChange(){
		if(this.bloquearTiposDePagamento){
			this.liberarTiposDePagamento = false;
			this.tiposDePagamentoSelecionados = [];
		}
	}

	handleLiberarChange(){
		if(this.liberarTiposDePagamento){
			this.bloquearTiposDePagamento = false;
			this.tiposDePagamentoSelecionados = [];
		}
	}

	abrirDialogoDeConfirmacao(){
		this.confirmaAlteracoes.mostrar()
	}
}
